// @flow
import * as React from "react";
import Button from "@material-ui/core/Button";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Popover from "../../common/Popover";
import { withRouter } from "react-router-dom";

function RAEHelpButton(props) {
  return (
      <Popover
        text="RAE Activation Help"
        style={{
          margin: "0px 0 0",
          float: "right"
        }}
      >
        <Button
          data-qa="help-outline-icon"
          onClick={() => {
            props.history.push(`/help`);
          }}
        >
          <HelpOutlineIcon fontSize="large"/>
        </Button>
      </Popover>
  );
}

export default withRouter(RAEHelpButton);
