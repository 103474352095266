import { createMuiTheme } from "@material-ui/core/styles";

let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#8c8a8a'
    }
  },
  overrides: {
    MuiDialog: {
      paper: {
        opacity: "0.95"
      }
    },
    MuiDrawer: {
      paper: {
        width: "50%",
        minWidth: "800px"
      }
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'Roboto',
    color: "#000000",
    h4: {
      fontWeight: "300",
      fontSize: "38px",
      letterSpacing: "-0.25px",
      lineHeight: "50px",
      textAlign: "center"
    },
    subtitle1: {
      color: "#464343",
      fontSize: "22px",
      lineHeight: "30px",
      fontWeight: "300"
    },
    h5: {
      fontSize: "26px",
      color: "#464343",
      fontWeight: "300",
      lineHeight: "40px",
      textAlign: "center"
    },
    h6: {
      fontSize: "16px",
      color: "#000000",
      lineHeight: "22px"
    },
    caption: {
      fontSize: "14px"
    },
    body1: {
      color: "#464343",
      fontWeight: "300"
    }
  }
});

export default theme;
