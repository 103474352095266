import React, { Component } from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import ConnectionImage from "../../../../assets/ConnectionSK.svg";
import NewRAEImage from "../../../../assets/New RAE -00SK.svg";
import Button from "@material-ui/core/Button";
import { withTheme } from "@material-ui/core";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 663px;
  background-color: #ffffff;
`;

const InternetAndRAE = styled.div`
  display: block;
  position: relative;
  overflow: visible;
  width: 1024px;
  height: 310px;
  margin-top: 35px;
`;

const ConnectionImg = styled.div`
  display: block;
  position: absolute;
  overflow: visible;
  width: 362px;
  height: 321px;
  left: 0px;
  top: 0px;
  z-index: 5;
`;

const RAEImg = styled.div`
  display: block;
  position: absolute;
  overflow: visible;
  width: 369px;
  height: 127px;
  left: 328px;
  top: 236px;
  z-index: 5;
`;

const Instructions = styled.div`
  display: block;
  position: absolute;
  width: 760px;
  height: 100px;
  left: 162px;
  top: 17px;
`;

const LowerRectangle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 392px;
  border-radius: 0 0 2px 2px;
  background-color: #F6F6F6;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 276px;
  height: 32px;
  margin-top: 145px;
`;

const ButtonTextStyle = {
  textTransform: 'none',
  color: '#FFFFFF',
  backgroundColor: '#0075B8',
  fontSize: '13px',
  fontWeight: 500,
  letterSpacing: '0.33px',
  lineHeight: '13px',
  textAlign: 'center',
  minWidth: '276px',
  padding: '10px',
};

type Props = {
  errorMsg: ?string,
  loading: boolean,
  history: Object,
  deviceActions: Object,
  nextTab: Function,
  theme: Object,
  disabled: boolean
};

type State = {
};

class ConnectRAE extends Component<Props, State> {

  handleConnected = () => {
    this.props.deviceActions.connectRAE();
  };


  render() {
      const { disabled } = this.props;

      return <Container>
          <InternetAndRAE>
            <Instructions>
            <Typography variant="h4">
               Connect the RAE WAN port to the Internet with an Ethernet cable. Then, turn on the RAE.
            </Typography>
            </Instructions>
              <ConnectionImg>
               <img src={ConnectionImage} alt="WAN Connection to Internet" />
              </ConnectionImg>
              <RAEImg>
                <img src={NewRAEImage} alt="The RAE" />
              </RAEImg>
          </InternetAndRAE>
          <LowerRectangle>
            <ButtonWrapper>
             <Button style={ButtonTextStyle} fullWidth={true} disabled={disabled} onClick={this.handleConnected}>
              The RAE is connected to the Internet
             </Button>
            </ButtonWrapper>
          </LowerRectangle>
        </Container>;
  }
}

export default withTheme()(ConnectRAE);
