import React, { Component } from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import config from "../../../../config";

import RAEHealthyImage from "../../../../assets/RAE02SK.svg";
import RAEErrorImage from "../../../../assets/RAE ErrorSK.svg";
import SuccessImage from "../../../../assets/ShapeSK.svg";
import ErrorImage from "../../../../assets/Error.png";
import { withTheme } from "@material-ui/core";

const { uiUrl } = config;
const ntsUrl = uiUrl+'/nts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 663px;
  background-color: #ffffff;
`;

const Status = styled.div`
  display: block;
  position: relative;
  overflow: visible;
  width: 1024px;
  height: 310px;
  margin-top: 35px;
`;

const Instructions = styled.div`
  display: block;
  position: absolute;
  width: 760px;
  height: 100px;
  left: 162px;
  top: 17px;
`;

const IconImg = styled.div`
  display: block;
  position: absolute;
  overflow: visible;
  width: 1024px;
  height: 127px;
  left: 0px;
  top: 85px;
`;

const MoreInstructions = styled.div`
  display: block;
  position: absolute;
  width: 1024px;
  height: 100px;
  left: 0px;
  top: 155px;
`;

const RAEImg = styled.div`
  display: block;
  position: absolute;
  overflow: visible;
  width: 369px;
  height: 127px;
  left: 328px;
  top: 236px;
  z-index: 5;
`;

const LowerRectangle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 392px;
  border-radius: 0 0 2px 2px;
  background-color: #F6F6F6;
`;

const NextSteps = styled.div`
  display: block;
  position: relative;
  width: 416px;
  height: 100px;
  margin-top: 140px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 235.35px;
  height: 32px;
  margin-top: 145px;
`;

const ButtonTextStyle = {
  textTransform: 'none',
  color: '#FFFFFF',
  backgroundColor: '#0075B8',
  fontSize: '13px',
  fontWeight: 500,
  letterSpacing: '0.33px',
  lineHeight: '20px',
  textAlign: 'center',
};


const STATUS = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR"
};

type Props = {
  errorMsg: ?string,
  loading: boolean,
  history: Object,
  deviceActions: Object
};

type State = {
  status: string;
};

class RAEStatus extends Component<Props, State> {

  state = {
    status: (this.props.errorMsg === null) ? STATUS.SUCCESS : STATUS.ERROR
  };

  handleButton = () => {
    this.props.deviceActions.retryActivation();
  }

  render() {
      const status = this.state.status;
      const rae = this.props.rae;
      
      const adminUrl = "https://hardwaremanager.netflixpartners.com/host/"+rae.raeHostname;

      return <Container>
              <Status>
               <Instructions>
            {status === STATUS.SUCCESS && (
            <Typography variant="h4" align="center">
               RAE Activation Complete.
            </Typography>)}
            {status === STATUS.ERROR && (
            <div>  
            <Typography variant="h4" align="center">
               The RAE could not complete setup.
            </Typography>
            <Typography variant="subtitle1" style={{fontSize: '22px'}} align="center">
               Ensure that all of your cables are in working condition and try again.
            </Typography>
            </div>)}
               </Instructions>

            <IconImg>
              {status === STATUS.SUCCESS && (<img src={SuccessImage} alt="RAE Activation Success" />)}
              {status === STATUS.ERROR && (<img src={ErrorImage} alt="RAE Activation Error" />)}
            </IconImg>
            {status === STATUS.SUCCESS && (<MoreInstructions>
              <Typography variant="h5" align="center">
               Wait approximately 10-15 minutes for the RAE to update software. The RAE will display the screen shown below when the update is complete.
              </Typography>
            </MoreInstructions>)}

            <RAEImg>
              {status === STATUS.SUCCESS && (<img src={RAEHealthyImage} alt="RAE Healthy" />)}
              {status === STATUS.ERROR && (<img src={RAEErrorImage} alt="RAE Error" />)}
            </RAEImg>
               </Status>

               <LowerRectangle>
                 {status === STATUS.SUCCESS && (
                 <NextSteps>
                   <Typography variant="h5">
                     Next Steps
                   </Typography>
            
                   <hr />
                   <Typography variant="h6" align="left">
                        1. Connect your device to RAE or Eleven<br/>
                        2. Turn on the device and launch Netflix<br/>
                        3. Navigate to <a href={adminUrl}>Hardware Manager</a> to claim or manage your devices.
                        4. Navigate to <a href={ntsUrl}>NTS</a> to start testing<br/>
                   </Typography>

                 </NextSteps>
            )}
            {status === STATUS.ERROR && (
              <ButtonWrapper>
                <Button style={ButtonTextStyle} onClick={this.handleButton} fullWidth={true}>
              Try again
                </Button>
              </ButtonWrapper>
            )}

              </LowerRectangle>
        </Container>;
  }
}

export default withTheme()(RAEStatus);
