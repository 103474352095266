// @flow
import { ajax } from "rxjs/ajax";

import { catchError } from 'rxjs/operators';

import config from "../config";
import { token } from "./user";

const { uiUrl } = config;

const isInIframe = (): boolean => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const handleAuthError = () => {
  if (isInIframe()) {
    window.parent.postMessage(
      {
        event_id: 401,
        data: {
          path: window.location.hash
        }
      },
      window.location.origin
    );
  } else {
    document.cookie = `PTOOLS-LAST-VISITED=${window.location};domain=.netflix.com;path=/`;
    window.location.href = `${uiUrl}/home/#/login`;
  }
};

const request = (
  method: string,
  url: string,
  body: ?Object | string,
  queryParams: ?Object,
  headers: ?Object
) => {
  const queryString = queryParams
    ? Object.keys(queryParams).reduce((accum, key, index) => {
        const str = `${index > 0 ? "&" : ""}${key}=${queryParams
          ? encodeURIComponent(queryParams[key])
          : ""}`;

        return accum + str;
      }, "?")
    : "";

  const head = {
    "Content-Type": "application/json",
    ...headers
  };

  if (window.sso && window.sso.method() === "meechum") {
    let authz_token = token();
    if (authz_token)
        head.Authorization = `Bearer ${authz_token}`;
  }

  const obs$ =  ajax({
    method: method,
    url: url+queryString,
    crossDomain: true,
    withCredentials: true,
    body: body,
    headers: head
  }).pipe(
    catchError(error => {
      if (error.status === 401) {
        handleAuthError();
      } else {
        throw error;
      }
  }));

  return obs$;
};

export default request;
