import { Cookies } from "react-cookie";
const cookies = new Cookies();

const username = () => {
  let pToolsCookie = cookies.get("PTOOLS-USER");
  return pToolsCookie != null ? pToolsCookie.username : '';
}

const isNetflixEmployee = () => {
  let pToolsCookie = cookies.get("PTOOLS-USER");
  return pToolsCookie != null ? pToolsCookie.isNetflixEmployee : false;
}

const token = () => {
  return cookies.get("npp.sso.access_token");
}

export { username as default, isNetflixEmployee, token };
