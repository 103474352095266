// @flow
import React, { Component } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";

const ButtonWrapper = styled.div`
  display: block;
  position: relative;
  width: 392px;
  height: 69px;
  border-bottom-style: solid;
  border-bottom-color: #0075bb;
  border-bottom-width: 5px;
`;

const ButtonInner = styled.div`
  display: block;
  position: relative;
  width: 219px;
  height: 44px;
  top: 14px;
  left: 87px;
`;

const SelectedTextStyle = {
  textTransform: 'none',
  color: '#2b2828',
  fontSize: '16px',
  fontWeight: 500,
  letterSpacing: '-0.1px',
};

const NormalTextStyle = {
  textTransform: 'none',
  color: '#8c8a8a',
  fontSize: '16px',
  fontWeight: 500,
  letterSpacing: '-0.1px',
};

const NormalBorderStyle = {
  borderBottomColor: '#ffffff'
}

const SelectedBorderStyle = {
  borderBottomColor: '#0075bb'
}

type State = { };

type Props = {
  label: string,
  value: string,
  selected: boolean,
  action: Function
};

class RAEMenuButton extends Component<Props, State> {
  state = {
  };

  doAction = () => { 
   if (this.props.action && this.props.value)
     this.props.action(this.props.value) 
  };

 render() {
  const {label, selected } = this.props;
  const textstyle = selected ? SelectedTextStyle : NormalTextStyle;
  const borderstyle = selected ? SelectedBorderStyle : NormalBorderStyle;

  return (
      <ButtonWrapper style={borderstyle}>
        <ButtonInner>
          <Button style={textstyle} disabled={true} disableRipple={true} disableFocusRipple={true} fullWidth={true} onClick={this.doAction}>
            {label}
          </Button>
        </ButtonInner>
      </ButtonWrapper>
  );
 }
}

export default RAEMenuButton;
