// @flow
import React, { Component } from "react";
import styled from "styled-components";

const InputBox = styled.input.attrs({
  maxLength: "1",
  size: "1"
})`
  width: 132px;
  height: 174px;
  font-size: 132px;
  font-family: Roboto;
  text-align: center;
  margin: 0 8px 0 8px;
  border: 0;
  box-shadow: inset 0px 0px 0px 1px rgba(217, 217, 217, 1);
  &:focus {
    outline: none;
    box-shadow: inset 0px 0px 0px 1px rgba(4, 117, 184, 1);
  }
`;

type Props = {
  tabIndex: string,
  onEnter?: Function,
  onChange?: Function
};

type State = {
  value: string
};

class CodeInputBox extends Component<Props, State> {
  state = {
    value: ""
  };
  
  nextEl: ?HTMLElement;
  prevEl: ?HTMLElement;

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  focus() {
    this.inputRef.current.focus();
  }

  value() {
    return this.state.value;
  }

  componentDidMount() {
    this.nextEl = document.querySelector(
      `[tabindex="${Number(this.props.tabIndex) + 1}"]`
    );

    this.prevEl = document.querySelector(
      `[tabindex="${Number(this.props.tabIndex) - 1}"]`
    );
  }

  handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const val = event.currentTarget.value;

    this.setState(
      {
        value: val.toUpperCase()
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange();
        }

        if (this.nextEl && val !== "") {
          this.nextEl.focus();
        }
      }
    );

  };

  handleKeyUp = (event: Event) => {
    if (event.key === "Enter") {
      if (this.props.onEnter) {
        this.props.onEnter();
      }
    }

    if (event.key === "Backspace" && this.prevEl) {
      this.prevEl.focus();
    }
  };

  render() {
    return (
      <InputBox
        onFocus={e => e.target.select()}
        onKeyUp={this.handleKeyUp}
        onChange={this.handleChange}
        tabIndex={this.props.tabIndex}
        value={this.state.value}
        ref={this.inputRef}
      />
    );
  }
}

export default CodeInputBox;
