import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { withTheme } from '@material-ui/core/styles';
import styled from "styled-components";
import theme from "../../../theme";
import "../../../App.css";
import Sso from "../../common/Sso";
import HeaderImage from "../../../assets/Header-SK.png";

import Alert from "../../common/Alert";

import ConnectRAE from "./tabs/ConnectRAE";
import ActivateRAE from "./tabs/ActivateRAE";
import RAEStatus from "./tabs/RAEStatus";
import RAEHelpButton from "./RAEHelpButton";
import RAEMenuButton from "./RAEMenuButton";

const TABS = {
  CONNECT_RAE: "CONNECT RAE",
  ACTIVATE_RAE: "ACTIVATE RAE",
  UPDATE_RAE: "UPDATE RAE",
  STATUS_RAE: "RAE STATUS"
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: auto;
`;

const Header = styled.div`
  width: 100%;
  height: 64px;
  background-color: #2b2828;
`;


const NavContainer = styled.div`
  display: flex;
  width: 100%;
  height: 74px;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #ffffff;
  margin: 0 auto 0 auto;
`;

const ARNav = styled.div`
  display: flex;
  flex-direction: row;
  width: 1280px;
  height 74px;
  background-color: #ffffff;
  box-shadow: 0 1px 0 0 #E4E4E4;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 662px;
  margin 0;
`;

const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const AlertContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const AlertContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 100%;
`;


type State = { 
  tabIndex: string,
  alert: Object
};

type Props = {
  device: Object,
  deviceActions: Object,
  store: Object,
  history: Object,
  nextTab: Function
};

class ActivateRAEPage extends Component<Props, State> {
  state = {
    tabIndex: TABS.CONNECT_RAE,
    alert: null
  };

  selectTab = (index) => {
    this.setState({ tabIndex: index });
  };

  nextTab = () => {
    switch (this.state.tabIndex) {
      case TABS.CONNECT_RAE:
        this.setState({tabIndex: TABS.ACTIVATE_RAE});
        break;
      case TABS.ACTIVATE_RAE:
        this.setState({tabIndex: TABS.UPDATE_RAE});
        break;
      case TABS.UPDATE_RAE:
        this.setState({tabIndex: TABS.STATUS_RAE});
        break;
      default:
        break;
    }
  };

/*
  componentDidMount() {
    fetch(`${process.env.PUBLIC_URL}/alert.json`)
    .then(response => {
        if (response.status !== 200) {
            console.log(`No alert found: ${response.status}`)
            return
        }
        response.json().then(alertdata => {
            this.setState({
                alert: alertdata
            })
        })
    })
  }
*/
  render() {
    const rae = this.props.rae;
    const connecting = !rae.raeConnected;
    const activating = rae.raeConnected && !rae.raeClaimed && !rae.raeError;
    const done_or_error = rae.raeClaimed || rae.raeError;
    const errorMsg = rae.activateRaeErrorMsg;
    const { alert } = this.state;

    return <Container>
       <Sso>
      <MuiThemeProvider theme={theme}>
        <Header>
          <img src={HeaderImage} height="64" width="1280" alt="Page Header"/>
        </Header>
        <NavContainer>
          <ARNav>
            <RAEMenuButton label="01 Connect RAE" value={TABS.CONNECT_RAE} selected={connecting} {...this.props}/>
            <RAEMenuButton label="02 Enter Activation Code" value={TABS.ACTIVATE_RAE} selected={activating} {...this.props}/>
            <RAEMenuButton label="03 Activation Status" selected={done_or_error} value={TABS.STATUS_RAE} {...this.props}/>
            <RAEHelpButton  {...this.props}/>
          </ARNav>
        </NavContainer>
        { alert ? (<AlertContainer>
          <AlertContent>
            <Alert type={alert.type} {...this.props}>{alert.text}</Alert>
          </AlertContent>
        </AlertContainer>) : null }
        <Content>
          <TabContent>
                {connecting && (
                  <ConnectRAE nextTab={this.nextTab} disabled={alert !== null} theme={theme} {...this.props}/>
                )}

                {activating && (
                  <ActivateRAE nextTab={this.nextTab} deviceActions={this.props.deviceActions} {...this.props}/>
                )}

                {done_or_error && (
                  <RAEStatus errorMsg={errorMsg} deviceActions={this.props.deviceActions} {...this.props}/>
                )}
          </TabContent>
        </Content>
      </MuiThemeProvider>
       </Sso>
    </Container>;
  }
}

export default withTheme()(withRouter(ActivateRAEPage));
