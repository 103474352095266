// @flow
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { Spinner } from "npp-components";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import MoreInfo from "../../common/MoreInfo";
import SuccessMsg from "../../dialog/SuccessMsg";
import ErrorMsg from "../../dialog/ErrorMsg";
import DialogTitle from "../../dialog/DialogTitle";
import ClaimCodeInput from "./ClaimCodeInput";

const Content = styled.div`
  flex: 0 1 25%;
`;

const ButtonWrapper = styled.div`
  margin: 70px auto 0 auto;
  width: 253.35px;
`;

const Container = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: auto;
`;

const ButtonTextStyle = {
  textTransform: 'none',
  color: '#FFFFFF',
  backgroundColor: '#0075B8',
  fontSize: '13px',
  fontWeight: 500,
  letterSpacing: '0.33px',
  lineHeight: '13px',
  textAlign: 'center',
  minWidth: 276,
  padding: '10px',
};

type Props = {
  errorMsg: ?string,
  loading: boolean,
  history: Object,
  match: Object,
  deviceActions: Object,
  onClaimed: Function,
  children?: any,
  claimMessage?: string
};

type State = {
  done: boolean,
  activating: boolean,
  claimCode: string,
  validationError: ?string
};

class ClaimCodePage extends Component<Props, State> {
  state = {
    done: false,
    activating: false,
    claimCode: '',
    validationError: null
  };

  componentDidMount() {
    this.props.deviceActions.clearClaimErrors();
  }

  componentWillReceiveProps(nextProps: Object) {
    if (!this.props.loading && nextProps.loading) {
      this.setState({ done: false });
    } else if (
      this.props.loading &&
      !nextProps.loading &&
      !nextProps.errorMsg
    ) {
      this.setState({ done: true,
                      activating: false });
      this.props.onClaimed && setTimeout(this.props.onClaimed, 2 * 1000);
    } else if (this.props.loading && !nextProps.loading && nextProps.errorMsg) {
      this.setState({ done: true,
                      activating: false });
    }
  }

  handleClaimDevice = () => {
    const { claimCode } = this.state;

    if (claimCode.length !== 6) {
      this.setState({
        validationError:
          "Reg code should be alphanumeric and 6 characters long."
      });
    } else {
      this.setState({
        activating: true,
        validationError: null
      });
      
	  console.log("Claiming RAE with reg code "+claimCode);
      this.props.deviceActions.activateRae(claimCode);
    }
  };

  onClaimCodeChange = (claimCode) => {
      this.setState({
        claimCode
      });
  }

  getSuccessMessage = () => {
      const successMessage = React.Children.toArray(this.props.children)
          .filter(child => child.type === SuccessMsg);

      if (successMessage.length) {
        return successMessage[0];
      } else {
        return <div />
      }
  }

  getTitle = () => {
      const title = React.Children.toArray(this.props.children)
          .filter(child => child.type === DialogTitle);

      if (title.length) {
        return title[0];
      } else {
          return <div />
      }
  }

  getMoreInfo = () => {
      const info = React.Children.toArray(this.props.children)
          .filter(child => child.type === MoreInfo);

      if (info.length) {
        return info[0];
      } else {
          return <div />
      }
  }

  render() {
    const { errorMsg } = this.props;
    const { activating, done, validationError } = this.state;

    if (activating) { 
      return (
        <Container>
           <Typography variant="h4">
             Activating RAE...
          </Typography>
        <Spinner
          size={150}
          mode="indeterminate"
          color="blue600"
          thickness={10}
          show={true}
        />
        </Container>
      );
    }

    return (
      <Container>

        {done && !errorMsg ? (
          this.getSuccessMessage()
        ) : null}

        {done ? <ErrorMsg msg={errorMsg} /> : null}
        {validationError ? <ErrorMsg msg={validationError} /> : null}

        {!done || (done && errorMsg) ? (
            this.getTitle()
        ) : null}

        {!done || (done && errorMsg) ? (
          <Content>
            <ClaimCodeInput boxCount={6} onEnter={this.handleClaimDevice} onChange={this.onClaimCodeChange} />

            <ButtonWrapper data-qa="claim-submit">
              <Button  style={ButtonTextStyle}
                kind="raised"
                type="button"
                color="primary"
                stretch="true"
                onClick={this.handleClaimDevice}
              >
                {this.props.claimMessage}
              </Button>
            </ButtonWrapper>

            {this.getMoreInfo()}

          </Content>
        ) : null}
      </Container>
    );
  }
}

export default withRouter(ClaimCodePage);
