// @flow
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import * as raeModule from "../redux/modules/rae";
import ActivateRAEHelp from "../components/raeActions/ActivateRAEHelp";

const mapStateToProps = (state: Object) => {
  return {
    loading: state.rae.activateRaeLoading,
    errorMsg: state.rae.activateRaeErrorMsg
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    deviceActions: bindActionCreators(raeModule.actionCreators, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ActivateRAEHelp));
