// @flow
import keyMirror from "fbjs/lib/keyMirror";
import { of } from "rxjs";
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ActionsObservable } from "redux-observable";
import {
  activateRae,
  awaitBundle$,
  checkAgentResult$
} from "../../services/rae";

/*
ACTIONS
 */
const ACTIONS = keyMirror({
  CONNECT_RAE: null,
  CONNECT_RAE_SUCCESS: null,
  ACTIVATE_RAE: null,
  AWAIT_BUNDLE: null,
  ACTIVATE_RAE_SUCCESS: null,
  ACTIVATE_RAE_ERROR: null,
  ACTIVATE_RAE_FAILURE: null,
  UPDATE_RAE: null,
  UPDATE_RAE_SUCCESS: null,
  UPDATE_RAE_ERROR: null,
  UPDATE_RAE_FAILURE: null,
  CLEAR_ACTIVATE_ERRORS: null,
  RETRY_ACTIVATION: null,
  RETRY_ACTIVATION_SUCCESS: null
});

/*
ACTION CREATORS
*/
const actionCreators = {
  connectRAE: () => ({
    type: ACTIONS.CONNECT_RAE,
    data: {
    },
    meta: {
      analytics: {
        type: "REDUX_EVENT_TRACK",
        payload: {
          type: "event",
          category: "RAE",
          action: "Connect RAE"
        }
      }
    }
  }),

  connectRAESuccess: () => ({
    type: ACTIONS.CONNECT_RAE_SUCCESS,
    data: {},
    meta: {
      analytics: {
        type: "REDUX_EVENT_TRACK",
        payload: {
          type: "event",
          category: "RAE",
          action: "Connect RAE Success"
        }
      }
    }
  }),

  activateRae: (regCode: string) => ({
    type: ACTIONS.ACTIVATE_RAE,
    data: {
      regCode,
    },
    meta: {
      analytics: {
        type: "REDUX_EVENT_TRACK",
        payload: {
          type: "event",
          category: "RAE",
          action: "Activate RAE",
          label: regCode
        }
      }
    }
  }),

  awaitBundle: (data: Object) => ({
    type: ACTIONS.AWAIT_BUNDLE,
    data: data,
    meta: {
      analytics: {
        type: "REDUX_EVENT_TRACK",
        payload: {
          type: "event",
          category: "RAE",
          action: "Await Bundle",
          label: data.hostname
        }
      }
    }
  }),

  activateRaeSuccess: (data: Object) => ({
    type: ACTIONS.ACTIVATE_RAE_SUCCESS,
    data: data,
    meta: {
      analytics: {
        type: "REDUX_EVENT_TRACK",
        payload: {
          type: "event",
          category: "RAE",
          action: "Activate RAE Success",
          label: JSON.stringify(data)
        }
      }
    }
  }),

  activateRaeError: (data: string) => ({
    type: ACTIONS.ACTIVATE_RAE_ERROR,
    data: data,
    meta: {
      analytics: {
        type: "REDUX_EVENT_TRACK",
        payload: {
          type: "event",
          category: "RAE",
          action: "Activate RAE Error",
          label: JSON.stringify(data)
        }
      }
    }
  }),

  activateRaeFailure: (data: string) => ({
    type: ACTIONS.ACTIVATE_RAE_FAILURE,
    data: data,
    meta: {
      analytics: {
        type: "REDUX_EVENT_TRACK",
        payload: {
          type: "event",
          category: "RAE",
          action: "Activate RAE Failure",
          label: JSON.stringify(data)
        }
      }
    }
  }),

  checkUpdateRae: (regCode: string) => ({
    type: ACTIONS.UPDATE_RAE,
    data: {
      regCode
    },
    meta: {
      analytics: {
        type: "REDUX_EVENT_TRACK",
        payload: {
          type: "event",
          category: "RAE",
          action: "Update RAE",
        }
      }
    }
  }),

  updateRaeSuccess: (data: Object) => ({
    type: ACTIONS.UPDATE_RAE_SUCCESS,
    data: data,
    meta: {
      analytics: {
        type: "REDUX_EVENT_TRACK",
        payload: {
          type: "event",
          category: "RAE",
          action: "Update RAE Success",
          label: JSON.stringify(data)
        }
      }
    }
  }),

  updateRaeError: (data: string) => ({
    type: ACTIONS.UPDATE_RAE_ERROR,
    data: data,
    meta: {
      analytics: {
        type: "REDUX_EVENT_TRACK",
        payload: {
          type: "event",
          category: "RAE",
          action: "Update RAE Error",
          label: JSON.stringify(data)
        }
      }
    }
  }),

  updateRaeFailure: (data: string) => ({
    type: ACTIONS.UPDATE_RAE_FAILURE,
    data: data,
    meta: {
      analytics: {
        type: "REDUX_EVENT_TRACK",
        payload: {
          type: "event",
          category: "RAE",
          action: "Update RAE Failure",
          label: JSON.stringify(data)
        }
      }
    }
  }),

  clearClaimErrors: () => ({
    type: ACTIONS.CLEAR_ACTIVATE_ERRORS,
    meta: {
      analytics: {
        type: "REDUX_EVENT_TRACK",
        payload: {
          type: "event",
          category: "RAE",
          action: "Clear Rae Activation Errors"
        }
      }
    }
  }),

  retryActivation: () => ({
    type: ACTIONS.RETRY_ACTIVATION,
    meta: {
      analytics: {
        type: "REDUX_EVENT_TRACK",
        payload: {
          type: "event",
          category: "RAE",
          action: "Retry Rae Activation"
        }
      }
    }
  }),

  retryActivationSuccess: () => ({
    type: ACTIONS.RETRY_ACTIVATION_SUCCESS,
    meta: {
      analytics: {
        type: "REDUX_EVENT_TRACK",
        payload: {
          type: "event",
          category: "RAE",
          action: "Retry Activation Success"
        }
      }
    }
  })
};


/*
EPICS
 */
const epics = {
  connectRaeEpic: (action$: ActionsObservable<*>) => {
    return action$.ofType(ACTIONS.CONNECT_RAE).pipe(
        map(() => {
          console.log("RAE connected.");
          return actionCreators.connectRAESuccess;
      }
    ));
  },

  activateRaeEpic: (action$: ActionsObservable<*>) => {
    var claimcode;
    return action$.ofType(ACTIONS.ACTIVATE_RAE).pipe(
        mergeMap(action => {
          claimcode = action.data.regCode;
          return activateRae(claimcode).pipe(
            mergeMap(response => {
              return awaitBundle$(response.response).pipe(
                map(abresponse => {
                  return actionCreators.activateRaeSuccess(abresponse.response);
                }));
            }),
            catchError(response => {
              let msg = `An error occurred while attempting to register the RAE with reg code ${
                claimcode
              }`;
              if (response.status === 403 || response.status === 404) {
                msg = `RAE with reg code ${claimcode} not found.`;
                return of(actionCreators.activateRaeError(msg));
              } else if (response.status === 409) {
                msg = `This RAE has already been activated.`;
                return of(actionCreators.activateRaeError(msg));
              } else {
                msg = `Provisioning operation failed to activate RAE with reg code ${claimcode}.`;
              }
              return of(actionCreators.activateRaeError(msg));  // previously used activateRaeFailure to display error on status page
           })
        )}
    ));
  },


  updateRaeEpic: (action$: ActionsObservable<*>) => {
    var regCode;
    return action$.ofType(ACTIONS.UPDATE_RAE).pipe(
        mergeMap(action => {
          regCode = action.data.regCode;
            // check agent result completes when update is complete or timesout
           return checkAgentResult$(regCode).pipe(
            map(response => {
             return actionCreators.updateRaeSuccess(response.response);
            }),
            catchError(response => {
              let msg = `An error occurred while attempting to update the software for the RAE having reg code ${
                regCode
              }`;
              return of(actionCreators.updateRaeFailure(msg));
           })
        )}
    ));
  },

  retryActivationEpic: (action$: ActionsObservable<*>) => {
    return action$.ofType(ACTIONS.RETRY_ACTIVATION).pipe(
        map(() => {
          return actionCreators.retryActivationSuccess;
      }
    ));
  },
};

const rae_initial_state = {
    raeConnected: false,
    raeClaimed: false,
    raeUpdated: false,
    raeActivated: false,
    raeError: false,
    activationCode: '',
    raeHostname: '',
    activateRaeLoading: false,
    awaitingBundle: false,
    activateRaeErrorMsg: null,
    raeStatus: null,
    retrying: false
  };

/*
REDUCER
 */
const rae = (
  state: {
    raeConnected: boolean,
    raeClaimed: boolean,
    raeUpdated: boolean,
    raeActivated: boolean,
    raeError: boolean,
    activationCode: string,
    raeHostname: string,
    activateRaeLoading: boolean,
    awaitingBundle: boolean,
    activateRaeErrorMsg: ?string,
    raeStatus: ?string,
    retrying: boolean
  } = rae_initial_state,
  action: { type: string, data?: mixed }
) => {
  switch (action.type) {
    // RAE Connected
    case ACTIONS.CONNECT_RAE:
      return {
        ...state,
        raeConnected: true
      };
    // Activate RAE
    case ACTIONS.ACTIVATE_RAE:
      return {
        ...state,
        activateRaeLoading: true,
        activateRaeErrorMsg: null
      };
    case ACTIONS.AWAIT_BUNDLE:
      return {
        ...state,
        awaitingBundle: true,
        activateRaeErrorMsg: null
      };
    case ACTIONS.ACTIVATE_RAE_SUCCESS:
      return {
        ...state,
        raeClaimed: true,
        raeHostname: action.data.hardware_id,
        activationCode: action.data.reg_code,
        activateRaeLoading: false,
        awaitingBundle: false,
        activateRaeErrorMsg: null
      };
    case ACTIONS.ACTIVATE_RAE_ERROR:
      return {
        ...state,
        activateRaeLoading: false,
        awaitingBundle: false,
        activateRaeErrorMsg: action.data
      };
    case ACTIONS.ACTIVATE_RAE_FAILURE:
      return {
        ...state,
        raeError: true,
        activateRaeLoading: false,
        awaitingBundle: false,
        activateRaeErrorMsg: action.data
      };
    // Update RAE
    case ACTIONS.UPDATE_RAE:
      return {
        ...state,
        activateRaeLoading: true,
        activateRaeErrorMsg: null
      };
    case ACTIONS.UPDATE_RAE_SUCCESS:
      return {
        ...state,
        raeUpdated: true,
        raeActivated: true,   // For now, it's considered 'activated' when update is complete
        activateRaeLoading: false,
        activateRaeErrorMsg: null
      };
    case ACTIONS.UPDATE_RAE_ERROR:
      return {
        ...state,
        activateRaeLoading: false,
        activateRaeErrorMsg: action.data
      };
    case ACTIONS.UPDATE_RAE_FAILURE:
      return {
        ...state,
        raeError: true,
        activateRaeLoading: false,
        activateRaeErrorMsg: action.data
      };
    case ACTIONS.CLEAR_ACTIVATE_ERRORS:
      return {
        ...state,
        raeError: false,
        activateRaeLoading: false,
        activateRaeErrorMsg: null
      };

    case ACTIONS.RETRY_ACTIVATION:
      return {
        ...rae_initial_state,
        retrying: true
      };

    default:
      return state;
  }
};

export { ACTIONS, actionCreators, epics, rae as default };
