import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import red from "@material-ui/core/colors/red";

const Container = styled.div`
  position: relative;
  top: 0;
  width: 100%;
  height: 100px;
  background-color: #fff;
  border-bottom: 1px solid ${props => props.color};
  color: ${props => props.color};
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function ErrorMsg(props) {
  if (!props.msg) {
    return <div />;
  }

  return (
    <Container color={red[500]}>
      <Typography
        style={{ color: red[500] }}
        type="headline"
        data-qa="error-message"
      >
        {props.msg || ""}
      </Typography>
    </Container>
  );
}

export default ErrorMsg;
