import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import { history } from "./redux/store";
import ActivateRAE from "./containers/ActivateRAE";
import ActivateRAEHelp from "./containers/ActivateRAEHelp";
import "./index.css";


class Routes extends React.Component {
  render() {
    return (
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/help" component={ActivateRAEHelp} />
          <Route path="/raesetup" component={ActivateRAE} />
          <Redirect to="/raesetup" />
        </Switch>
      </ConnectedRouter>
    );
  }
}

export default Routes;
