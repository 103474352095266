import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./redux/store.js";
import Routes from "./routes.js";
import { unregister } from "./registerServiceWorker";
import { CookiesProvider } from "react-cookie";

const render = () =>
  ReactDOM.render(
  <CookiesProvider>
    <Provider store={store}>
      <Routes />
    </Provider>
  </CookiesProvider>,
    document.getElementById("root")
  );

window.ssoScript ? window.ssoScript.onLoad(render) : render();

unregister();
