//@flow
import * as React from "react";
import styled from "styled-components";
import ArrowIcon from "@material-ui/icons/ExpandMore";
import Link from "@material-ui/core/Link";
import Divider from "@material-ui/core/Divider";

const HelpText = styled.div`
  margin: 40px auto;
  max-width: 500px;
  text-align: left;
  align-self: center;
`;

const Arrow = styled(ArrowIcon)`
  width: 20px !important;
  margin-left: 10px;
  vertical-align: middle;
  ${({ open }) => open && "transform: rotate(180deg)"};
  color: #8c8a8a;
`;

type Props = {
  children: React.Node,
  title: string
};

type State = { open: boolean };

class MoreInfo extends React.Component<Props, State> {
  state = { open: false };

  render() {
    const { open } = this.state;
    const { children, title } = this.props;
    return (
      <div>
        <p>
          <Link
            onClick={() => {
              this.setState({ open: !open });
            }}
          >
            {title}
          </Link>
          <Arrow open={open} />
        </p>
        <div
          style={{
            visibility: open ? "visible" : "hidden",
            width: "100%"
          }}
        >
          <Divider thickness="1" />
          <HelpText>{children}</HelpText>
        </div>
      </div>
    );
  }
}

export default MoreInfo;
