import React from "react";
import Typography from "@material-ui/core/Typography";

function DialogTitle(props) {
  return (
    <div>
      <Typography type="headline" style={{ marginBottom: "20px" }}>
        {props.title}
      </Typography>
      <Typography type="subheading" paragraph style={{ marginBottom: "30px" }}>
        {props.subtitle}
      </Typography>
    </div>
  );
}

export default DialogTitle;
