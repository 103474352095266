// @flow
const stagingRegex = /staging[.-]/,
  devRegex = /dev[.-]/,
  localPartnerToolsRegex = /local[.-]/,
  corpRegex = /corp.netflix/,
  localRegex = /localhost/;

const configs = {
  dev: {
    registryUrl: "https://dev-raedeviceregistry.nrd.netflix.com",
    bridgeUrl: "https://dev-api.nrd.netflix.com/REST/v1/aethyr/bridge",
    uiUrl: "https://dev-partnertools.nrd.netflix.com",
    bifrostUrl: "https://dev-bifrost.nrd.netflix.com"
  },
  staging: {
    registryUrl: "https://staging-raedeviceregistry.nrd.netflix.com",
    bridgeUrl: "https://staging-api.nrd.netflix.com/REST/v1/aethyr/bridge",
    uiUrl: "https://staging-partnertools.nrd.netflix.com",
    bifrostUrl: "https://staging-bifrost.nrd.netflix.com"
  },
  prod: {
    registryUrl: "https://rae-device-registry.nrd.netflix.com",
    bridgeUrl: "https://api.nrd.netflix.com/REST/v1/aethyr/bridge",
    uiUrl: "https://partnertools.nrd.netflix.com",
    bifrostUrl: "https://bifrost.nrd.netflix.com"
  }
};

const initConfigs = (env?: string) => {
  const currentUrl = window.location.hostname.toLowerCase();

  let stack;
  
  if (env) {
    stack = env;
  } else if (stagingRegex.test(currentUrl)) {
    stack = "staging";
  } else if (devRegex.test(currentUrl)) {
    stack = "dev";
  } else if (corpRegex.test(currentUrl)) {
    stack = "dev";
  } else if (localRegex.test(currentUrl)) {
    stack = "dev";
  } else if (localPartnerToolsRegex.test(currentUrl)) {
    stack = "prod";
  } else {
    stack = "prod";
  }

  return {
    stack: stack,
    ...configs[stack]
  };
};

export default initConfigs();
