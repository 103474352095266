import tap from "redux-tap";
import { createTracker, providers } from "npp-analytics";

/* Analytic Instrumentation */
const providerId = providers.defaultProvider.id;

const analytics = createTracker({
  zones: [{ providerId, name: "deviceManagement", id: "UA-48091793-5" }]
});

const analyticsMiddleware = tap(
  ({ meta }) => meta && meta.analytics,
  ({ type, payload }) => analytics.dispatch(payload, { type })
);

export default analyticsMiddleware;
