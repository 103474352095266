import { createStore, applyMiddleware, combineReducers } from "redux";
import { createEpicMiddleware, combineEpics } from "redux-observable";
import thunk from "redux-thunk";
import createHistory from "history/createHashHistory";
import { routerReducer, routerMiddleware } from "react-router-redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
// import devices, * as devicesModule from "./modules/devices";
// import device, * as deviceModule from "./modules/device";
// import bridge, * as bridgeModule from "./modules/bridge";
import rae, * as raeModule from "./modules/rae";
// import dpi, * as dpiModule from "./modules/dpi";
import analyticsMiddleware from "./analytics";

const history = createHistory();

const epics = combineEpics(
  ...Object.values(raeModule.epics)
);
const epicMiddleware = createEpicMiddleware();

const reducers = combineReducers({
  rae,
  router: routerReducer
});

const store = createStore(
  reducers,
  composeWithDevTools(
    applyMiddleware(
      thunk,
      epicMiddleware,
      routerMiddleware(history),
      analyticsMiddleware
    )
  )
);

epicMiddleware.run(epics);


export { store as default, history };
