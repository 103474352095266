import React, { Component } from "react";
import styled from "styled-components";
import { withStyles } from '@material-ui/core/styles';
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import HelpItems from "../../../assets/HelpItems.json"
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"; 
import IconButton from '@material-ui/core/IconButton';
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CloseIcon from "@material-ui/icons/Close";
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Sso from "../../common/Sso";
import theme from "../../../theme";
import HeaderImage from "../../../assets/Header-SK.png";
import config from "../../../config";

const { uiUrl } = config;
const helpUrl = uiUrl+'/partnerdevicemanager/#/tickets';

const styles = theme => ({
  icon: {
    margin: theme.spacing.unit * 2,
  },
})

type State = {
  open: boolean,
  done: boolean,
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: auto;
  background-color: #ffffff
`;

const Header = styled.div`
  width: 100%;
  height: 64px;
  background-color: #2b2828;
`;

const Banner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 64px;
  background-color: #ffffff;
`;

const BannerElements = styled.div`
  display: flex;
  flex-direction: row;
  width: 1280px;
  height: 64px;
  background-color: #ffffff;
`;

const Spacer = styled.div`
  display: flex;
  flex-grow: 1;
`;

const Content = styled.div`
  display: flex;
  width: 1280px;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: auto;
  background-color: #ffffff;
`;

const HeadlineContainer = styled.div`
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 32px;
`;

const HelpContainer = styled.div`
  width: 60%;
  justify-content: left;
  align-items: left;
  margin: auto;
`;

function renderItem(props, item) {
//  const { classes } = props;
       return <ExpansionPanel key={item.key}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">{item.key}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{backgroundColor: "#fafafa"}}>
                  <Typography variant="body1">
                    {item.details}<br/>
                    {item.action}<br/>
                    Please file a <a href={helpUrl}>help ticket</a> if you need assistance.
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>;
}


class ActivateRAEHelp extends Component<Props, State> {
  state = {
    open: true,
    done: false
  };

  onCancel = () => {
    this.setState({ open: false });
    setTimeout(() => {
      this.props.history.goBack();
    }, 500);
  };


  render() {
//    const { loading } = this.props;
//    const { open } = this.state;
    const listHelpItems = HelpItems.map((item) => renderItem(this.props, item));

      return <Container>
        <Sso>
          <MuiThemeProvider theme={theme}>
            <Header>
              <img src={HeaderImage} height="64" width="1280" alt="Page Header"/>
            </Header>
            <Banner>
              <BannerElements>
                <HelpOutlineIcon fontSize="large" style={{margin: "10px"}}/> 
                <Typography variant="h6" style={{ marginLeft: "32px", marginTop: "17px"}}>RAE Help</Typography> 
                <Spacer /> 
                <IconButton onClick={this.onCancel}><CloseIcon fontSize="large"/></IconButton>
              </BannerElements>
            </Banner>
            <Content>
              <HeadlineContainer><Typography variant="h4">RAE Error Codes</Typography></HeadlineContainer>
              <HelpContainer>
                {listHelpItems}
              </HelpContainer>
            </Content>
          </MuiThemeProvider>
        </Sso>
      </Container>;
  }
}
export default withStyles(styles)(ActivateRAEHelp);
