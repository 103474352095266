// @flow
import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import { withTheme } from "@material-ui/core/styles";
//import green from "@material-ui/core/colors/green";

class Alert extends Component<{
  children: Object,
  type: "info" | "error" | "success"
}> {
  render() {
    const { type } = this.props;
    const colors = {
      error: 'error',
      info: 'textPrimary',
      success: 'textSecondary'
    };

    return (
      <div>
        <Typography color={colors[type]} variant="h5">
          {this.props.children}
        </Typography>
      </div>
    );
  }
}

export default withTheme()(Alert);
