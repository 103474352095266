import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import ClaimCodePage from "../../../claim/ClaimCodePage";
import SuccessMsg from "../../../dialog/SuccessMsg";
import MoreInfo from "../../../common/MoreInfo";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 977px;
  height: 663px;
  background-color: #F6F6F6;
`;

const Instructions = styled.div`
  display: flex;
  width: 977px;
  height: 100px;
  margin-top: 53px;
`;

const CodeEntry = styled.div`
  display: flex;
  width: 100%;
  margin-top: 60px;
`;



type Props = {
  errorMsg: ?string,
  loading: boolean,
  history: Object,
  deviceActions: Object,
  nextTab: Function
};

type State = {
};

class ActivateRAE extends Component<Props, State> {
  render() {

      return <Container>
              <Instructions>
               <Typography variant="h4">After the system has booted up, enter the 6-digit 
                 activation code that is displayed on the RAE LCD screen.</Typography>
              </Instructions>
              <CodeEntry>
                <ClaimCodePage claimMessage="I've entered the code" onClaimed={this.props.nextTab} {...this.props}>

                  <SuccessMsg msg={"Your RAE was activated successfully. Please refer to the screen for further instructions."} />

                  <MoreInfo title="Can't see the code or wrong code?">
                    <Typography type="body1">
                    A RAE will normally display an activation code on the LCD screen on startup. 
                    If the connection to the internet is not valid, there should be an error code 
                    displayed on the LCD screen instead.<br/>
                    You can use <a href="#/help">help</a> to diagnose this error code.<br/>
                    If you need further assistance, please contact support by opening a support ticket.
                    </Typography>
                  </MoreInfo>
                </ClaimCodePage>
              </CodeEntry>
         </Container>;
  }
}

export default withRouter(ActivateRAE);
