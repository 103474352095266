// @flow
import config from "../config";
import request from "./http";
import { interval } from 'rxjs';
import { filter, timeout, mergeMap, take, catchError } from 'rxjs/operators';

const { registryUrl } = config;

/**
 * [activateRAE claims a RAE for the current user]
 */
export const activateRae = (regCode : string) => {
  var result = request(
    "POST",
    `${registryUrl}/REST/v2/aethyr/registry/registration/${regCode.toUpperCase()}`
    , null
  );

  return result;
};

/**
 * [getActivationStatus checks if a RAE was activated]
 */
export const getActivationStatus = (hostname : string) => {
  var result = request(
    "GET",
    `${registryUrl}/REST/v2/aethyr/registry/hardware/${hostname}`
    , null
  );

  return result;
};

/**
 * [getAgentStatus checks if an agent is reachable and active following activation]
 */
export const getAgentStatus = (regCode : string) => {
  var result = request(
    "GET",
    `${registryUrl}/REST/v2/aethyr/registry/agents/regCodes/${regCode.toUpperCase()}`
    , null
  );

  return result;
};

export const checkAgentResult$ = (
  regCode: string,
  pollInterval: number = 3000,
  waitLimit: number = 90000
  ) => {
  return interval(pollInterval)
           .pipe(
             mergeMap(() => getAgentStatus(regCode)),
             catchError((error, source$) => {
               if (error.status !== 404) {
                 throw error;
               } else {
                 return source$;
             }}),
             filter(response => response.status === 200),
             take(1),
             timeout(waitLimit));
};

export const awaitBundle$ = (
  hostdata: Object,
  pollInterval: number = 3000,
  waitLimit: number = 120000
  ) => {
  return interval(pollInterval)
           .pipe(
             mergeMap(() => getActivationStatus(hostdata.hostname)),
             catchError((error, source$) => {
               if (error.status !== 404) {
                 throw error;
               } else {
                 return source$;
             }}),
             filter(response => response.response.bundle_delivered !== undefined),
             take(1),
             timeout(waitLimit));
};



