// @flow

import React, { Component } from "react";
import ReactDOM from "react-dom";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";

const PopoverContainer = styled.div`
  text-align: center;
  background-color: rgba(33, 32, 30, 0.8);
  position: absolute;
  padding: 3px 5px;
  border-radius: 2px;
  white-space: nowrap;
`;

const Container = styled.div`
  position: relative;
`;

type Props = {
  text: string,
  children: Object,
  anchor?: Object
};

type State = {
  show: boolean,
  top: number,
  right: number
};

class Popover extends Component<Props, State> {
  state = {
    show: false,
    top: 0,
    right: 0
  };

  handleMouseEnter = () => {
    this.setState({
      show: true,
      top: this.props.anchor ? this.props.anchor.top : this.getHeight(),
      right: this.props.anchor ? this.props.anchor.right : this.getWidth()
    });
  };

  handleMouseLeave = () => {
    this.setState({ show: false });
  };

  getHeight = () => {
    const el = ReactDOM.findDOMNode(this.refs.child);
    if (el && el.clientHeight) {
      return Number(el.clientHeight) / 2 - 14;
    } else {
      return 0;
    }
  };

  getWidth = () => {
    const el = ReactDOM.findDOMNode(this.refs.child);
    if (el && el.clientWidth) {
      return Number(el.clientWidth) + 16;
    } else {
      return 0;
    }
  };

  render() {
    const { text } = this.props;
    const { show, top, right } = this.state;

    return (
      <Container
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        {...this.props}
      >
        {React.cloneElement(React.Children.only(this.props.children), {
          ref: "child"
        })}

        {show ? (
          <PopoverContainer style={{ top: top + "px", right: right + "px" }}>
            <Typography
              type="body1"
              style={{
                color: "#FFF",
                fontSize: "11px",
                lineHeight: "22px",
                fontWeight: 400,
                padding: "0px 11px"
              }}
            >
              {text}
            </Typography>
          </PopoverContainer>
        ) : null}
      </Container>
    );
  }
}

export default Popover;
