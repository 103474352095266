import React from "react";
import Typography from "@material-ui/core/Typography";
import CheckCircle from "@material-ui/icons/CheckCircle";

function SuccessMsg(props) {
  if (!props.msg) {
    return <div />;
  }
  return (
    <div>
      <CheckCircle
        style={{ color: "#4CB327", width: "86px", height: "86px" }}
      />
      <Typography type="subheading" paragraph data-qa="success-msg">
        {props.msg}
      </Typography>

      {props.detail ? (
        <Typography type="body1" paragraph data-qa="success-msg-detail">
          {props.detail}
        </Typography>
      ) : null}
    </div>
  );
}

export default SuccessMsg;
