// @flow
import React, { Component } from "react";
import { withTheme } from "@material-ui/core/styles";

type SsoState = {
    authenticated: boolean
};

type SsoProps = {
    children: Object
};

class Sso extends Component<SsoProps, SsoState> {

    constructor(props) {
        super(props);

        this.state = {
            authenticated: false
        };
    }

    componentDidMount() {

        const onSessionStarted = () => {
            window.sso.events.removeListener('session-started', onSessionStarted)
            this.setState({
                authenticated: true
            });
        }

        window.ssoScript.onLoad(() => {
            window.sso.events.on('session-started', onSessionStarted);
            window.sso.authenticate({ display: window.sso.displayType.PAGE });
        });
    }

    render() {
        return (
            <div>
                { this.state.authenticated ? this.props.children : "loading..."}
            </div>
        );
    }
}

export default withTheme()(Sso);
