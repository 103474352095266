// @flow
import React, { Component } from "react";
import CodeInputBox from "./CodeInputBox";

type Props = {
  onEnter: Function,
  onChange: Function,
  boxCount: number
};

export default class ClaimCodeInput extends Component<Props> {

    inputBoxes: Array<HTMLInputElement> = [];

    componentDidMount() {
        //console.log(this.inputBoxes);
        this.inputBoxes[0].focus();
    }

    claimCode() {
        return this.inputBoxes
            .map(box => box.value())
            .filter(val => /[a-zA-Z0-9]/.test(val))
            .reduce((prev, next) => {
                return prev + next;
            }, "");
    }

    onInputChange = () => {
        if (this.props.onChange) {
            this.props.onChange(this.claimCode());
        }
    }

    render() {
        const { onEnter, boxCount } = this.props;

        return (
        <div>
            { [...Array(boxCount).keys()].map((i) => i+1).map((i) =>
                (<CodeInputBox
                key={`codeinputbox-${i}`}
                data-qa={`claim-entry-${i}`}
                tabIndex={`${i}`}
                onChange={this.onInputChange}
                onEnter={() => onEnter(this.claimCode())}
                ref={el => {
                    this.inputBoxes[Number(i-1)] = el;
                }}
                />)
            )}
        </div>
        );
    }
}